import React from "react";
// import Backdrop from "../Backdrop/Backdrop";

// import Model from "../model/Model";
function MasterDeleteModal({ setMessage, Delete, content }) {
  return (
    <div
      className={`fixed bg-black  top-0 flex  flex-col  justify-center
    bottom-0 right-0  z-50  overflow-y-scroll  h-full w-full bg-opacity-30`}
    >
      <div className=" ">
        <div className="   flex items-center   justify-center  mx-auto tablet:w-7/12 h-screen">
          <div
            className="bg-white shadow-sm shadow-gray-500
           rounded-lg   px-5    font-openSansFive text-lg flex  flex-col   justify-center"
          >
            <div className="py-8 text-gray-700">
              {content ? content : "Are you sure you wanna delete this?"}
            </div>
            <div className=" flex flex-row-reverse items-start   text-base  w-11/12 pb-8 gap-3">
              <div
                className="bg-red-500 text-white shadow-sm shadow-red-400 hover:scale-105
               hover:bg-red-700 flex px-3 py-1 cursor-pointer  capitalize rounded-lg justify-center items-center"
                onClick={() => Delete()}
              >
                <button>Delete</button>
              </div>
              <div
                className="bg-gray-300  hover:text-white text-gray-700 px-3 py-1 
              flex capitalize shadow-sm hover:scale-105
               hover:bg-gray-400 shadow-gray-400 rounded-lg justify-center items-center"
              >
                <button onClick={() => setMessage(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterDeleteModal;

import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import { AuthContext } from "../HOC/ContextApi/AuthCheck";
import Layout from "../HOC/Layout/layout";
import DashBoard from "../pages/DashBoard";
import DepositByQRCode from "../pages/Deposit/DepositByQRCode";
import DepositByBank from "../pages/Deposit/DepositByBank";
import CoinBuyData from "../pages/CoinBuyData";
import Withdraw from "../pages/Withdraw";
import Contact from "../pages/Contact";

// import Product from "../components/Page/Product/index";
// import Service from "../components/Page/Service";
// import { useContext } from "react";
// import Login from "../components/Page/Login";
// import Billing from "../components/Page/Billing";
// import Category from "../components/Page/Category";
// import Brand from "../components/Page/Brand";
// // import Staff from "../components/Page/Staff";
// import Customer from "../components/Page/Customer";
// import DashBoard from "../components/Page/Dashboard";
// import DashId from "../components/PageComponent/DashboardId/DashId";
// import Payment from "../components/Page/Payment/index";
// import Purchase from "../components/Page/Purchase";
// import Attendence from "../components/Page/Attendence";
// import Booking from "../components/Page/Booking";
// import ProceedPayment from "../components/PageComponent/ProceedPayment/ProceedPayment";
// import PageNotFound from "../components/Page/pageNotFound/PageNotFound";

function App() {
  // const context = useContext(AuthContext);
  // console.log(context);
  return (
    <div className="">
      <Router>
        <Routes>
          <Route exact path="/" element={<DashBoard />} />
          <Route exact path="/deposit_QR" element={<DepositByQRCode />} />
          <Route exact path="/deposit_bank" element={<DepositByBank />} />
          <Route exact path="/coin_buy" element={<CoinBuyData />} />
          <Route exact path="/withdraw" element={<Withdraw />} />
          <Route exact path="/contact" element={<Contact />} />
          {/* <Route exact path="/category" element={<Category />} /> */}
          {/* <Route exact path="/brand" element={<Brand />} /> */}
          {/* <Route exact path="/purchase" element={<Purchase />} /> */}
          {/* <Route exact path="/billing" element={<Billing />} />
          <Route exact path="/billing/:id" element={<ProceedPayment />} />
          <Route exact path="/booking" element={<Booking />} />
          <Route exact path="/attendence" element={<Attendence />} />
          <Route exact path="/customers" element={<Customer />} />
          <Route exact path="/staff" element={<Staff />} />
          <Route exact path="/login" element={<Login />} /> */}
          {/* <Route exact path="*" element={<PageNotFound />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

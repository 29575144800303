import React from "react";

function Table({ Head, children, height }) {
  return (
    <div className={`w-[95%]  mx-auto  my-3  overflow-y-auto  `}>
      <table
        className=" w-11/12 laptop:w-full  border  "
        cellPadding={0}
        cellSpacing={0}
      >
        <thead className=" sticky top-0  ">
          <tr className="bg-white   laptop:w-full border-b ">
            {Head?.map((val, i) => {
              return (
                <td
                  key={i}
                  // width={val.width}
                  className="capitalize text-[#4F4F4F] text-center  px-2 text-lft py-5  font-semibold"
                >
                  {val.title}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className="">{children}</tbody>
      </table>
    </div>
  );
}

export default Table;

import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import axios from "../../../../HOC/CreateAxios/CreateAxios";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import storage from "../../../../HOC/FIREBASE/FIREBASE";
import * as yup from "yup";
import image from "../../Resources/Images/bitBgRemove.png";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../HOC/Layout/layout";
import Table from "../../UI/Table";
import { MdDelete } from "react-icons/md";
import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
import ImageModel from "../../UI/ImageModel/ImageModel";
function DepositByQRCode({ setShowCategory }) {
  //   const [idStore, setIdStore] = useState(false);
  const [message, setMessage] = useState(false);
  // form names and keys for mapping
  const [progress, setprogress] = useState(0);
  const [progressShow, setprogressShow] = useState(false);
  const [modelClick, setModelClick] = useState(false);
  const [filteredData, setFilteredData] = useState("");

  const Navigate = useNavigate();

  //   const Submit = async (val) => {
  //     try {

  //       axios
  //         .post("/Category", val)
  //         .then((res) => {
  //           if (res.status === 200) {
  //             toast.success("Category has been added sucessfully");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  let Spinner;
  //   if (progressShow) {
  //     Spinner = (
  //       <div
  //         style={{ background: "rgba(0,0,0,.5)" }}
  //         className="fixed h-screen w-screen top-0 left-0 z-50 flex items-center justify-center"
  //       >
  //         <div className="h-44 w-44  rounded-md z-50">
  //           <CircularProgressbar value={progress} text={`${progress}%`} />
  //         </div>
  //       </div>
  //     );
  //   }
  const Submit = () => {
    console.log("first");
  };
  const Thead = [
    { title: "s.nO", with: "20%" },
    { title: "name", with: "20%" },
    { title: "date", with: "20%" },
    { title: "voucherImage", with: "20%" },
    { title: "status", with: "20%" },
    { title: "action", with: "20%" },
  ];
  const Tbody = [
    { name: "apple", createdAt: "2022/11/05", image: image },
    { name: "banana", createdAt: "2022/11/05", image: image },
    { name: "strawberry", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
    { name: "pineapple", createdAt: "2022/11/05", image: image },
  ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option["name"].toLowerCase().indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  //   const DeleteItems = (i) => {
  //     let removeItems = storeData;
  //     removeItems.splice(i, 1);
  //     setStoreData([...removeItems]);
  //     setMessage(false);
  //   };
  const DeleteItems = () => {
    console.log("delete");
  };
  const Conformation = (id) => {
    setMessage(true);
    // setIdStore(id);
    // console.log(id);
  };

  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        // id={idStore}
        Delete={DeleteItems}
        setMessage={setMessage}
      />
    );
  }
  return (
    <Layout>
      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        Filter={Filter}
        placeholder={"search by name..."}
      />
      <Table Head={Thead}>
        {Filter(Tbody).map((val, i) => {
          return (
            <tr
              key={i}
              width={val.width}
              className="border-b text-center hover:bg-white"
            >
              <td className="px-3 py-7 ">{i + 1}</td>
              <td className="px-3 capitalize">{val.name}</td>
              <td className="px-1">{val.createdAt}</td>
              <td className="px-3" onClick={() => setModelClick(true)}>
                <div className="flex justify-center">
                  {" "}
                  <img
                    src={image}
                    alt="Loading..."
                    width={20}
                    height={20}
                    className="w-16 h-16 rounded-full hover:scale-105 cursor-pointer object-cover overflow-hidden bg-black -900 object-top "
                  />
                </div>
                {modelClick && (
                  <ImageModel image={val.image} setModelClick={setModelClick} />
                )}
              </td>
              <td className="px-3">{val.name}</td>

              <td className="px-3">
                <div
                  className=" p-2.5 flex gap-3 items-center 
                     border-gray-300 "
                >
                  <div
                    className="px-3 py-1 border hover:bg-red-500 hover:text-white  rounded-[3px]  cursor-pointer"
                    onClick={() => Conformation(i)}
                  >
                    <MdDelete />
                  </div>

                  {ConformationMessage}
                </div>
              </td>
            </tr>
          );
        })}
      </Table>
    </Layout>
  );
}

export default DepositByQRCode;

import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { TiArrowBack } from "react-icons/ti";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "../../api/axios";
import * as yup from "yup";
// import img from "../../../Resources/Logo.png";
import { GoArrowSwitch } from "react-icons/go";
import { BiCross } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import Layout from "../../HOC/Layout/layout";
function DashBoard({ setShow }) {
  const [Images, setImages] = useState("");

  const buyTime = [
    { id: 1, option: "1" },
    { id: 2, option: "2" },
    { id: 3, option: "3" },
  ];
  let buyTimeOpt = [];
  buyTime.map((item, index) => {
    buyTimeOpt.push(item);
  });

  const buyForm = [
    {
      label: "Slogan",
      type: "text",
      apikey: "slogan",
      placeholder: "slogan",
      // min: "0",
    },
    {
      label: "Description",
      type: "text",
      apikey: "description",
      // min: "1",
      placeholder: "description",
    },
    {
      label: "partners",
      type: "text",
      apikey: "partners",
      placeholder: "partners",
      // options: [{ id: 0, option: "select ", apikey: "" }, ...buyTimeOpt],
    },
  ];

  const buySchema = yup.object().shape({
    slogan: yup.string().required("required"),
    description: yup.string().required("required"),
    partners: yup.string().required("required"),
  });

  return (
    <Layout>
      <div className="w-full text- [#303132]  relative">
        <Formik
          initialValues={{
            slogan: "",
            description: "",
            partners: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            console.log(values);
            //   console.log(storeBuyData);
            // values.storeSellData

            // try {
            //   setIsLoading(true);
            //   axios
            //     .post("/buy", values)
            //     .then((res) => {
            //       console.log(res);
            //       if (res.status === 200) {
            //         toast.success("Data has been sent sucessfully");
            //         setShow(false);
            //         setIsLoading(false);
            //         resetForm();
            //       }
            //     })
            //     .catch((err) => {
            //       console.log(err);
            //       setIsLoading(false);
            //     });
            // } catch (error) {
            //   console.log(error);
            // }
          }}
          validationSchema={buySchema}
        >
          {({ handleSubmit, errors, touched }) => {
            return (
              <Form className="  w-[95%]   rounded-[5px] mx-auto">
                <ToastContainer autoClose={1000} />
                <div className="text-xl font-medium  pt-6 text-[#686868] ">
                  {" "}
                  Welcome to Admin Dashboard!!
                </div>
                <div className="  gap-x-7 mt-5 gap-y-10 grid grid-cols-2  py-3 pb-10 ">
                  {buyForm.map((item, index) => {
                    if (item.type === "select") {
                      return (
                        <div className="    relative " key={index}>
                          <div className="absolute  -top-3 left-3  px-2 ">
                            <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[#F7FAFC]  capitalize">
                              {item.label}
                            </label>
                          </div>
                          <div
                            className=" border border-[#c8c8c8]  "
                            key={index}
                          >
                            <Field
                              className="h-14   outline-none  px-5 bg-[#F7FAFC] w-full   
                                text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                              as={item.type}
                              name={item.apikey}
                              placeholder={item.placeholder}
                            >
                              {" "}
                              {item.options?.map((opt, i) => {
                                return (
                                  <option
                                    id={opt.apikey}
                                    value={opt.apikey}
                                    key={i}
                                  >
                                    {opt.option}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>

                          {errors[item.apikey] && touched[item.apikey] ? (
                            <div className="text-red-500 text-sm mt-1 capitalize px-2">
                              {errors[item.apikey]}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div className="   relative " key={index}>
                          <div className="absolute  -top-3 left-3  px-2 ">
                            <label className="font-medium text-[13px] bg-[#F7FAFC] mx-auto  px-3  text-[#9b9b9b]   capitalize">
                              {item.label}
                            </label>
                          </div>
                          <div
                            className=" border border-[#ababab] "
                            key={index}
                          >
                            <Field
                              className="h-14 bg-[#F7FAFC]  outline-none  px-5  w-full   
                                  text-sm placeholder:text-xs sm:placeholder:text-sm focus:outline-primary"
                              type={item.type}
                              name={item.apikey}
                              min={item.min ? item.min : null}
                              // onChange={(e) =>
                              //   setStoreBuyData(e.target.value)
                              // }
                              placeholder={item.placeholder}
                            />
                          </div>

                          {errors[item.apikey] && touched[item.apikey] ? (
                            <div className="text-red-500 text-sm mt-1 capitalize px-2">
                              {errors[item.apikey]}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
                <div className=" my-4  gap-3 relative">
                  <div className="absolute  -top-3 left-3  px-2 ">
                    <label className="font-medium text-[13px]  mx-auto  px-3  text-[#9b9b9b] bg-[#F7FAFC]  capitalize">
                      Banner Image
                    </label>
                  </div>
                  <div>
                    <input
                      id="image"
                      type="file"
                      apikey="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        setImages(e.target.files[0]);
                      }}
                      placeholder="import your image here"
                      className="hidden"
                    />
                    <div className="my-7 border w-full  overflow-hidden p-1 border-gray-600  border-dotted ">
                      <label htmlFor="image">
                        {Images ? (
                          <img
                            src={URL.createObjectURL(Images)}
                            alt="loading"
                            width={100}
                            height={100}
                            accept="image/png, image/gif, image/jpeg"
                            className=" object-contain h-96 w-full object-top "
                          />
                        ) : (
                          <div className="h-96 w-full flex justify-center items-center cursor-pointer">
                            <div className="bg-gray-100 rounded-full flex  items-center justify-center w-44 h-44    ">
                              <div className="text-lg cursor-pointer">
                                {" "}
                                Add voucher here
                              </div>
                            </div>
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col  items-end  relative">
                    {/* {Images ? ( */}
                    <button
                      className={`px-[47px] py-[10px] bg-[#833CDC] flex items-center gap-1 rounded-md text-white ${
                        Images ? "" : "cursor-not-allowed"
                      }`}
                      type="submit"
                      disabled={Images ? false : true}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className=" w-fit "></div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default DashBoard;

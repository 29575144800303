import { NavLink, useLocation } from "react-router-dom";

import React, { useState } from "react";

function Navitem({ path, title, icon, subTitle, id, icon1, icon2 }) {
  const router = useLocation();
  console.log("router", router);
  const [showSubTitle, setShowSubTitle] = useState(false);
  return (
    <NavLink
      to={path}
      onClick={() => {
        setShowSubTitle(!showSubTitle);
      }}
      className={` ${
        router.pathname === path
          ? "  bg-mainColor text-secondMainColor "
          : " hover:bg-mainColor hover:text-secondMainColor   "
      }
      border
      ${subTitle?.map((vals) => {
        console.log();
        return vals.paths === router.pathname
          ? "  bg-mainColor text-secondMainColor "
          : null;
      })}
     tablet:mb-3.5 px-3 my-2 font-semibold   cursor-pointer rounded-md  capitalize
     flex mx-3 gap-2  relative items-center py-2
      `}
    >
      <div> {icon}</div>
      <div className="flex justify-between w-full">
        <div className="">{title}</div>
        {!showSubTitle ? (
          <div className="text-2xl px-8">{icon1}</div>
        ) : (
          <div className="text-2xl px-8">{icon2}</div>
        )}
      </div>

      {showSubTitle && (
        <div className="absolute z-20 bg-[#f3f8fa] w-[97%]  top-12 rounded-md">
          {subTitle?.map((sub, index) => {
            return (
              <NavLink to={sub.paths}>
                <div
                  className={` ${
                    router.pathname === sub.paths
                      ? "bg-[white]  text-secondMainColor "
                      : " hover:bg-[white] text-black  "
                  }
         w-[95%]  mx-auto px-2   tablet:my-4 my-2 font-semibold   cursor-pointer rounded-md 
             flex items-center gap-2   py-2`}
                >
                  <div> {sub.icon}</div>
                  <div>{sub.sub_title}</div>
                </div>
              </NavLink>
            );
          })}
        </div>
      )}
    </NavLink>
  );
}

export default Navitem;

import { NavData } from "../NavData";
import React from "react";
import Navitem from "../NavItem/Nav_item";
function Navitems() {
  return (
    <div className="">
      {NavData.map((val, i) => {
        return (
          <Navitem
            key={i}
            id={val.id}
            title={val.title}
            path={val.path}
            icon={val.icon}
            subTitle={val?.subTitle}
            icon1={val?.icon1}
            icon2={val?.icon2}
          />
        );
      })}
    </div>
  );
}

export default Navitems;

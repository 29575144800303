import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import axios from "../../../../HOC/CreateAxios/CreateAxios";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import storage from "../../../../HOC/FIREBASE/FIREBASE";
import * as yup from "yup";
import image from "../../Resources/Images/bitBgRemove.png";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../HOC/Layout/layout";
import Table from "../../UI/Table";
import { MdDelete } from "react-icons/md";
import MasterDeleteModal from "../../UI/MasterDeleteModal/MasterDeleteModal";
import TableFilter from "../../components/pageComponents/TableFilter/TableFilter";
function CoinBuyData({ setShowCategory }) {
  //   const [idStore, setIdStore] = useState(false);
  const [message, setMessage] = useState(false);
  // form names and keys for mapping
  const [progress, setprogress] = useState(0);
  const [progressShow, setprogressShow] = useState(false);
  const Navigate = useNavigate();
  const [filteredData, setFilteredData] = useState("");

  //   const Submit = async (val) => {
  //     try {

  //       axios
  //         .post("/Category", val)
  //         .then((res) => {
  //           if (res.status === 200) {
  //             toast.success("Category has been added sucessfully");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  let Spinner;
  //   if (progressShow) {
  //     Spinner = (
  //       <div
  //         style={{ background: "rgba(0,0,0,.5)" }}
  //         className="fixed h-screen w-screen top-0 left-0 z-50 flex items-center justify-center"
  //       >
  //         <div className="h-44 w-44  rounded-md z-50">
  //           <CircularProgressbar value={progress} text={`${progress}%`} />
  //         </div>
  //       </div>
  //     );
  //   }
  const Submit = () => {
    console.log("first");
  };
  const Thead = [
    { title: "s.nO", with: "20%" },
    { title: "coin", with: "20%" },
    { title: "amount", with: "20%" },
    { title: "time", with: "20%" },
    { title: "action", with: "20%" },
  ];
  const Tbody = [
    { coin: "atom", amount: "2000", time: "1" },
    { coin: "bitcoin", amount: "2000", time: "1" },
    { coin: "etherum", amount: "2000", time: "1" },
    { coin: "atom", amount: "2000", time: "1" },
    { coin: "atom", amount: "2000", time: "1" },
    { coin: "bitcoin", amount: "2000", time: "1" },
    { coin: "etherum", amount: "2000", time: "1" },
    { coin: "atom", amount: "2000", time: "1" },
    { coin: "atom", amount: "2000", time: "1" },
    { coin: "bitcoin", amount: "2000", time: "1" },
    { coin: "etherum", amount: "2000", time: "1" },
    { coin: "atom", amount: "2000", time: "1" },
  ];

  const HandleSearchInputChange = (e) => {
    // console.log(e.target.value);
    setFilteredData(e.target.value);
  };

  const Filter = (options) => {
    return options.filter((option, index) => {
      return (
        option["coin"].toLowerCase().indexOf(filteredData.toLowerCase()) > -1
      );
    });
  };
  //   const DeleteItems = (i) => {
  //     let removeItems = storeData;
  //     removeItems.splice(i, 1);
  //     setStoreData([...removeItems]);
  //     setMessage(false);
  //   };
  const DeleteItems = () => {
    console.log("delete");
  };
  const Conformation = (id) => {
    setMessage(true);
    // setIdStore(id);
    // console.log(id);
  };

  let ConformationMessage;
  if (message) {
    ConformationMessage = (
      <MasterDeleteModal
        // id={idStore}
        Delete={DeleteItems}
        setMessage={setMessage}
      />
    );
  }
  return (
    <Layout>
      <TableFilter
        HandleSearchInputChange={HandleSearchInputChange}
        placeholder={"search by coin..."}
      />
      <Table Head={Thead}>
        {Filter(Tbody).map((val, i) => {
          return (
            <tr
              key={i}
              width={val.width}
              className="border-b hover:bg-white text-center"
            >
              <td className="px-3 py-6 ">{i + 1}</td>
              <td className="px-3">{val.coin}</td>
              <td className="px-1">{val.amount}</td>

              <td className="px-3">{val.time} min</td>

              <td className="px-3">
                {/* <div
                  className=" p-2.5 flex gap-3 items-center 
                     border-gray-300 "
                > */}
                <div
                  className="px-3 py-1 w-fit mx-auto border hover:bg-red-500 hover:text-white  rounded-[3px]  cursor-pointer"
                  onClick={() => Conformation(i)}
                >
                  <MdDelete />
                </div>

                {ConformationMessage}
                {/* </div> */}
              </td>
            </tr>
          );
        })}
      </Table>
    </Layout>
  );
}

export default CoinBuyData;

import React from "react";
import logoImage from "../../../Resources/Images/bitBgRemove.png";
import Navitems from "../NavItems/Nav_items";

function Sidebar() {
  return (
    <div className="w-full bg-white border rounded-md h-[810px]">
      {/* large device */}
      <div
        className=" flex flex-col justify-between py-5  w-full  h-screen "
        // style={styles.fadeInLeft}
      >
        <div>
          <div className="flex ">
            <img
              src={"logoImage"}
              alt="loading ..."
              height="70px"
              width="70px"
              className="bg-black  rounded-full object-cover object-top"
            />
          </div>
          <div>
            <div className="flex m-1 gap-3 py-4 rounded-md items-center mt-5 bg-mainColor capitalize">
              <div>
                <img
                  src={logoImage}
                  alt="loading ..."
                  height="60px"
                  width="60px"
                  className=" bg-secondMainColor rounded-full object-cover object-top"
                />
              </div>
              <div className="">
                <div className="font-semibold text-secondMainColor">
                  dubai crypto
                </div>
                <div className="text-[14.5px] text-gray-600">admin</div>
              </div>
            </div>
            <hr className="w-[97%]  mx-auto mt-2.5 " />
          </div>
          <div className="py-8 ">
            <Navitems />
          </div>
        </div>
        <div className="text-white font-medium  text-sm tracking-wide text-center  w-full ">
          {" "}
          This copyright belongs to
          <div className="mt-[2px]">&copy; dubaiCrypto</div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

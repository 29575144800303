import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import React, { useState, useEffect } from "react";

import { MdDehaze } from "react-icons/md";
import logoImage from "../../../Resources/Images/bitBgRemove.png";
import { Link, useNavigate } from "react-router-dom";
// import Collapse from "@kunukn/react-collapse";
import Cookies from "js-cookie";
import { BiSearch } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";

function TopBar({ setShowSidebar, showSidebar, hideSideBar, setHideSideBar }) {
  const [toggleArrowDown, setToggleArrowDown] = useState(false);
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const Logout = () => {
    Cookies.remove("token");
    navigate("/Login");
  };
  // useEffect(() => {
  //   if (Cookies) {
  //     let username = Cookies.get("username");
  //     let role = Cookies.get("role");
  //     let data = username.split("@");
  //     console.log(data);
  //     setUserName(data[0]);
  //     setRole(role);
  //   }
  // }, [Cookies]);
  return (
    <div
      className="bg-mainColor border border-gray-200  z-40 elative laptop:z-50 w-full  mobileL:w-full  text-white flex 
     justify-between items-center px-2 tablet:px-8 py-4  "
    >
      <div onClick={() => setShowSidebar(!showSidebar)}>
        <MdDehaze className="w-8 h-10  text-[#575757] cursor-pointer" />
      </div>

      <div className="flex gap-10 items-center w-6/12  ">
        {/* <Link to="/billing"> */}{" "}
        {/* {role === "admin" ? (
          <div className="flex gap-3">
            <div>
              <button
                className="bg-gray-400 font-semibold px-6 py-2  text-sm
      rounded-md capitalize transition-all ease-in-out duration-300 
       hover:scale-105 text-white hover:bg-blue-500"
                onClick={() => {
                  setHideSideBar(true);
                  navigate("/attendence", { state: { hideSideBar } });
                }}
              >
                Attendence
              </button>
            </div>
            <div>
              <button
                className="bg-gray-400 font-semibold px-6 py-2  text-sm
      rounded-md capitalize transition-all ease-in-out duration-300 
       hover:scale-105 text-white hover:bg-SecondaryColor"
                onClick={() => {
                  setHideSideBar(true);
                  navigate("/billing", { state: { hideSideBar } });
                }}
              >
                billing
              </button>
            </div>
          </div>
        ) : (
          ""
        )} */}
        <div className="col-span-4   justify-start  items-center w-full">
          <div className="  bg-white rounded-full w-full gap-x-4 h- pl-4 flex items-center   ">
            <div className=" pl-2">
              <label htmlFor="search">
                <BsSearch className="text-gray-400" />
              </label>
            </div>
            <div className="   w-full">
              <input
                id="search"
                type="text"
                name="search"
                placeholder="search..."
                // value=""
                className="w-full placeholder:text-gray-400 text-black bg-mainColor border-2  border-white   rounded-full outline-none  py-2.5  pl-4"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="rounded-full ">
            {logoImage ? (
              <img
                src={logoImage}
                alt="loading..."
                height="70"
                width="70"
                className="bg-black rounded-full object-cover object-top"
              />
            ) : (
              <div
                className="rounded-full capitalize h-10 w-10  flex justify-center items-center
               bg-[#575757] text-white"
              >
                {userName[0]}
              </div>
            )}
          </div>
          <button
            onBlur={() => setToggleArrowDown(false)}
            className="flex gap-2 cursor-pointer items-center"
            onClick={() => setToggleArrowDown(!toggleArrowDown)}
          >
            <div className="">
              <div className="capitalize text-xs tablet:text-sm">
                {" "}
                {userName}
              </div>
              <div className="capitalize text-xs tablet:text-sm"> {role}</div>
            </div>
            <div>
              {!toggleArrowDown ? (
                <IoMdArrowDropdown className="w-8 h-8 text-[#575757]" />
              ) : (
                <IoMdArrowDropup className="w-8 h-8 text-[#575757]" />
              )}
            </div>
          </button>
          {/* <div className="w-full h-[1px] bg-gray-300 mb-5 hidden laptop:hidden laptop:mb-0"></div> */}

          {toggleArrowDown ? (
            <div className="absolute   z-[1000] top-12 right-8">
              {/* <Collapse
              isOpen={toggleArrowDown}
              // state ko value yeha change huni raicha like true or false
              className={`a`}
              transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
              aria-hidden={toggleArrowDown ? "false" : "true"}
              elementType="article"
              render={() => (
                <React.Fragment> */}{" "}
              <div
                className="tipCard px-2    rounded-md py-4 
                   "
              >
                <div
                  onClick={() => Logout()}
                  className="bg-white hover:bg-mainColor px-7  py-2 cursor-pointer
            rounded-md text-[#484848] font-semibold relative  z-50 capitalize  "
                >
                  logout
                </div>
              </div>
              {/* </React.Fragment>
              )}
            /> */}
            </div>
          ) : (
            ""
          )}

          {/* <div className="w-full h-[1px] bg-gray-300 mb-5 laptop:hidden laptop:mb-0"></div> */}
        </div>
      </div>
    </div>
  );
}

export default TopBar;

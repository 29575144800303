import {
  MdOutlineProductionQuantityLimits,
  MdOutlineCategory,
  MdMiscellaneousServices,
  MdConnectWithoutContact,
  MdEmojiPeople,
  MdDashboard,
  MdPayment,
  MdBook,
} from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { GiBank, GiTwoCoins } from "react-icons/gi";
import {
  BiCoin,
  BiMoneyWithdraw,
  BiPhone,
  BiPurchaseTag,
} from "react-icons/bi";
import { IoMdCloudDownload } from "react-icons/io";
import { LuContact, LuQrCode } from "react-icons/lu";
import { BsBank2 } from "react-icons/bs";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

export const NavData = [
  {
    title: "Dashboard",
    path: "/",
    icon: <MdDashboard className="w-6 h-6" />,
  },
  {
    title: "Deposit",
    icon: <GiBank className="w-6 h-6" />,
    icon1: <IoMdArrowDropup />,
    icon2: <IoMdArrowDropdown />,

    subTitle: [
      {
        sub_title: "by bank",
        paths: "/deposit_bank",
        icon: <BsBank2 />,
      },
      {
        sub_title: "by QRCode",
        paths: "/deposit_QR",
        icon: <LuQrCode />,
      },
    ],
  },
  {
    title: "Coin Buy",
    path: "/coin_buy",
    icon: <BiCoin className="w-6 h-6" />,
  },
  {
    title: "Withdraw",
    path: "/withdraw",
    icon: <BiMoneyWithdraw className="w-6 h-6" />,
  },
  {
    title: "Contact",
    path: "/contact",
    icon: <BiPhone className="w-6 h-6" />,
  },
];

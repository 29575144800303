import React, { useState } from "react";

function TableFilter({ HandleSearchInputChange, placeholder }) {
  return (
    <div>
      <div className="py-5 px-8">
        <div className=" w-4/12 ">
          <input
            type="text"
            name="filter"
            // value=""
            onChange={(e) => HandleSearchInputChange(e)}
            placeholder={placeholder}
            className="w-full border border-[#d6d6d6] px-3 py-2 rounded-md outline-none placeholder:text-[#c8c8c8] -300 
   bg-mainColor"
          />
        </div>
      </div>
    </div>
  );
}

export default TableFilter;

import React from "react";
import { MdClose } from "react-icons/md";
function ImageModel({ image, setModelClick }) {
  const handleClick = () => {
    setTimeout(() => {
      setModelClick(false);
    }, 0);
  };
  return (
    <div
      className={`fixed bg-black  top-0 flex    justify-center
bottom-0 right-0  z-40  overflow-y-scroll  h-full w-full bg-opacity-30`}
    >
      <div>
        <img src={image} width={100} height={100} className="w-full h-full" />
      </div>
      <div
        className="bg-mainColor  z-50 relative w-fit h-fit mt-8 p-2.5 cursor-pointer rounded-full"
        onClick={() => handleClick()}
      >
        <MdClose className="text-3xl" />
      </div>
    </div>
  );
}

export default ImageModel;
